import axios from "axios";
import { toast } from "react-toastify";

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const validateEmail = (email) => {
  email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const getToken = () => {
  return localStorage.getItem("token");
};

const createAxiosInstance = () => {
  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
  return axiosInstance;
};

//Register User
export const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${BACKEND_URL}/api/users/register`, userData, { withCredentials: true });
    if (response.statusText === "OK") {
      toast.success("User Registered Successfully");
    }
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    toast.error(message);
  }
};

//Login User
export const loginUser = async (userData) => {
  try {
    const response = await axios.post(`${BACKEND_URL}/api/users/login`, userData);
    if (response.statusText === "OK") {
      toast.success("Login Successfull..");
    }
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    toast.error(message);
  }
};

//Logout User
export const logoutUser = async () => {
  try {
    await axios.get(`${BACKEND_URL}/api/users/logout`);
    localStorage.removeItem("token");
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    toast.error(message);
  }
};

//Forgot Password
export const forgotPassword = async (userData) => {
  try {
    const response = await axios.post(`${BACKEND_URL}/api/users/forgotpassword`, userData);
    toast.success(response.data.message);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    toast.error(message);
  }
};

//Reset Password
export const resetPassword = async (userData, resetToken) => {
  try {
    const response = await axios.put(`${BACKEND_URL}/api/users/resetpassword/${resetToken}`, userData);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    toast.error(message);
  }
};

//Get Login Status
export const getLoginStatus = async () => {
  try {
    const axiosInstance = createAxiosInstance();
    const response = await axiosInstance.get(`${BACKEND_URL}/api/users/loginstatus`);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    toast.error(message);
  }
};

//Get User Profile
export const getUser = async () => {
  try {
    const axiosInstance = createAxiosInstance();
    const response = await axiosInstance.get(`${BACKEND_URL}/api/users/getuser`);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    toast.error(message);
  }
};

//Update Profile
export const updateUser = async (formData) => {
  try {
    const axiosInstance = createAxiosInstance();
    const response = await axiosInstance.patch(`${BACKEND_URL}/api/users/updateuser`, formData);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    toast.error(message);
  }
};

//Change Password
export const changePassword = async (formData) => {
  try {
    const axiosInstance = createAxiosInstance();
    const response = await axiosInstance.patch(`${BACKEND_URL}/api/users/changepassword`, formData);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    toast.error(message);
  }
};
