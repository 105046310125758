import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const API_URL = `${BACKEND_URL}/api/products/`;

const getToken = () => {
  return localStorage.getItem("token");
};

const createAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.request.use((config) => {
    // Get the token from localStorage before each request
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  return axiosInstance;
};

// Create New Product
const createProduct = async (formData) => {
  const axiosInstance = createAxiosInstance();
  const response = await axiosInstance.post(API_URL, formData);
  return response.data;
};

// Get all Products
const getProducts = async () => {
  const axiosInstance = createAxiosInstance();
  const response = await axiosInstance.get(API_URL);
  return response.data;
};

// Delete a Product
const deleteProduct = async (id) => {
  const axiosInstance = createAxiosInstance();
  const response = await axiosInstance.delete(API_URL + id);
  return response.data;
};

// Get a Product
const getProduct = async (id) => {
  const axiosInstance = createAxiosInstance();
  const response = await axiosInstance.get(API_URL + id);
  return response.data;
};

// Update Product
const updateProduct = async (id, formData) => {
  const axiosInstance = createAxiosInstance();
  const response = await axiosInstance.patch(`${API_URL}${id}`, formData);
  return response.data;
};

const productService = {
  createProduct,
  getProducts,
  getProduct,
  deleteProduct,
  updateProduct,
};

export default productService;
