import React, { useState } from "react";
import "./Sidebar.scss";
import { RiMenuFoldFill, RiMenuUnfoldFill, RiProductHuntLine } from "react-icons/ri";
import menu from "../../data/sidebar";
import SidebarItem from "./SidebarItem";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ children }) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const goHome = () => {
    navigate("/");
  };

  return (
    <div className="layout">
      <div className="sidebar" style={{ width: isOpen ? "230px" : "60px" }}>
        <div className="top_section">
          <div className="logo" style={{ display: isOpen ? "block" : "none" }}>
            <RiProductHuntLine style={{ cursor: "pointer" }} size={35} onClick={goHome} />
          </div>
          <div className="bars" style={{ marginLeft: isOpen ? "100px" : "0px" }}>
            {isOpen ? (
              <RiMenuFoldFill onClick={toggle} style={{ cursor: "pointer" }} />
            ) : (
              <RiMenuUnfoldFill onClick={toggle} style={{ cursor: "pointer" }} />
            )}
          </div>
        </div>
        {menu.map((item, index) => {
          return <SidebarItem key={index} item={item} isOpen={isOpen} />;
        })}
      </div>
      <main style={{ paddingLeft: isOpen ? "230px" : "60px", transition: "all 0.5s" }}>{children}</main>
    </div>
  );
};

export default Sidebar;
