import React from 'react'

const Footer = () => {
    return (
        <div>
            <div className="--flex-center --py2">
                <p>All rights reserved &copy; 2023</p>
            </div>
        </div>
    )
}

export default Footer
